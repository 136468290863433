import './PolystoneSection.scss';

function PolystoneSection() {
    return (
        <section className="polystone-section mb-5" id="polystone">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5  ">

                        <div className="bg"></div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  offset-lg-1 offset-xl-1 mt-sm-3 mt-3 mt-md-0 mt-lg-0 mt-xl-0 offset-1">
                        <h2 className="mr-1 title font-weight-bolder">Polystone Chemical</h2>

                        <p className="pb-2">Industry-leading products for the cosmetic nail industry.
                            Polystone Chemical brand stands for ‘quality made in Germany’ all over the world.
                        </p>

                        <p className="pb-2">UAB Detales plius is dealer of polystone chemical brand/</p>




                    </div>


                </div>
            </div>
        </section>
    );
}

export default PolystoneSection;
