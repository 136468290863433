
import './Cosmetics.page.scss';
import CosmeticsSection from "./components/CosmeticsSection/CosmeticsSection";
import Contacts from "../../components/Contacts/Contacts";
import PolystoneSection from "./components/PolystoneSection/PolystoneSection";
import OxiSection from "./components/OxiSection/OxiSection";

function CosmeticsPage() {
    return (
        <div className="cosmetics-container">
            <CosmeticsSection></CosmeticsSection>
            <OxiSection></OxiSection>

            <PolystoneSection></PolystoneSection>

        </div>
    );
}

export default CosmeticsPage;
