
import './CosmeticsSection.scss';

function CosmeticsSection() {
    return (
        <header className="cosmetics position-relative">

            <div className="container h-100 position-relative">
                <div className="row h-100 align-items-center justify-content-start ">
                    <div className="col-lg-6  text-left align-content-lg-start">
                        <span className="label mb-2">DETALĖS PLIUS</span>
                        <h1 className="text-white font-weight-bold">Cosmetics</h1>
                        <p className="text-white font-weight-lighter mb-5">Innovative UV Gel and Gel Polish for the professional nail industry.</p>

                    </div>

                </div>
            </div>
            <div className="cover"></div>

        </header>
    );
}

export default CosmeticsSection;
