import './OxiSection.scss';

function OxiSection() {
    return (
        <section className="oxinails-section bg-white" id="oxinails">
            <div className="container">
                <div className="row justify-content-center ">

                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 mr-1  mb-4 text">
                        <h2 className="mr-1 title font-weight-bolder mb-5">Oxinails</h2>
                        <p><strong>OXI (<a href="http://oxinails.eu/">http://oxinails.eu</a>)</strong> – it‘s a high-quality cosmetics and additional tools for manicure
                            professionals which have been created with love.</p>
                        <p><strong>OXI </strong> – It‘s everything the professional manicurist needs. OXI products are
                            certified and comply with all EU requirements.</p>
                        <p className="pb-2"><strong>OXI</strong> products are manufactured in German, Dutch, and US
                            factories.</p>


                        <p className="pb-2"> UAB Detales plius dealer of Oxi cosmetics brand</p>

                        <div className="pb-4">
                            <h5>OXI products include:</h5>
                            <ul>
                                <li>OXI Gel System–more than 20 types of gels</li>
                                <li>OXI Gel Paints- a wide range of gel paints</li>
                                <li>OXI Gel Polish–a wide collection of highly pigmented gel polish</li>
                                <li>OXI Bio Gel System – Eco-friendly gel system</li>
                                <li>OXIauxiliary liquids– high-quality liquids, degreaser solutions</li>
                                <li>OXI brushes – a wide range of brushes for nail art</li>

                            </ul>
                        </div>


                    </div>

                    <div className="col-6 mr-1  d-flex justify-content-center">
                        <img src="../../../../assets/images/oxi_logo.jpeg" width="300" height="300"/>
                    </div>


                </div>
            </div>
        </section>
    );
}

export default OxiSection;
